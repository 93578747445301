"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports._ConvertWebflowSettings = exports.PRICEOVERVIEWID = void 0;
var input_output_helper_const_1 = require("./const/input-output-helper.const");
var input_mapping_const_1 = require("./const/input.mapping.const");
function detectSection() {
    console.info('Note: automatically inferred finish section');
    return 'finish';
}
exports.PRICEOVERVIEWID = '#pricing-overview';
var finalSettings = typeof settings !== 'undefined' ? settings : undefined;
var _ConvertWebflowSettings = function (finalSettings) {
    var _a, _b, _c, _d;
    if (finalSettings) {
        finalSettings.averageCustomerDuration = 2.5;
        if (!((_a = finalSettings === null || finalSettings === void 0 ? void 0 : finalSettings.section) === null || _a === void 0 ? void 0 : _a.length)) {
            finalSettings.section = 'finish';
        }
        var tenantId = (0, input_output_helper_const_1._ReadFieldValue)(input_mapping_const_1._AllMappings.tenantId);
        if ((tenantId === null || tenantId === void 0 ? void 0 : tenantId.length) && !finalSettings.tenantKey) {
            finalSettings.tenantKey = tenantId;
        }
        finalSettings.environment =
            process.env.NODE_ENV.toUpperCase() !== 'DEVELOPMENT' ||
                (finalSettings === null || finalSettings === void 0 ? void 0 : finalSettings.hideDebugInfos)
                ? 'prod'
                : 'dev';
        finalSettings.minimumAge = (_b = finalSettings === null || finalSettings === void 0 ? void 0 : finalSettings.minimumAge) !== null && _b !== void 0 ? _b : 18;
        finalSettings.matured = (_c = finalSettings === null || finalSettings === void 0 ? void 0 : finalSettings.matured) !== null && _c !== void 0 ? _c : 18;
        finalSettings.magicLineUrl = "https://".concat(finalSettings === null || finalSettings === void 0 ? void 0 : finalSettings.tenantKey, ".api.magicline.com");
        finalSettings.athleticsUrl = "https://files.scaleyourgym.com/files/webflow/new-magicline/dev-php/athletics-api.php";
        finalSettings.agileaUrl = "https://europe-west3-named-catcher-373207.cloudfunctions.net/agilea-public-endpoint";
        finalSettings.yondUrl = "https://europe-west3-named-catcher-373207.cloudfunctions.net/yond-public-endpoint";
        finalSettings.assetsUrl = "https://files.scaleyourgym.com/files/webflow/new-magicline/".concat(finalSettings.environment === 'dev' ? 'dev' : '', "/assets");
    }
    //additional debug infos for dev environment;
    if (finalSettings.environment === 'dev') {
        if ((finalSettings === null || finalSettings === void 0 ? void 0 : finalSettings.minimumAge) > finalSettings.matured) {
            console.error('Note Minimum age ' +
                (finalSettings === null || finalSettings === void 0 ? void 0 : finalSettings.minimumAge) +
                '  is > than matured age ' +
                finalSettings.matured);
        }
        if (((_d = finalSettings === null || finalSettings === void 0 ? void 0 : finalSettings.startDate) === null || _d === void 0 ? void 0 : _d.length) &&
            !(finalSettings.startDate.includes('-') &&
                typeof finalSettings.startDate === 'string')) {
            console.error('You provided a incorrect startdate in the tenant settings. Either remove it or check the format "YYYY-MM-DD" e.g. "2023-06-05"');
        }
        if (finalSettings &&
            !$(exports.PRICEOVERVIEWID).length &&
            (finalSettings === null || finalSettings === void 0 ? void 0 : finalSettings.section) === 'finish') {
            console.error('Price Overview id is not set: ' + exports.PRICEOVERVIEWID);
        }
    }
    return finalSettings;
};
exports._ConvertWebflowSettings = _ConvertWebflowSettings;
