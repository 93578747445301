"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports._ScrollToPageElementOffset = exports._ScrollToTop = exports._CalculateAOV = exports._SimpleUUID = exports._RoundUpToTwoDecimals = exports._DiffTimes = exports._DataHide = exports._CollapseAndUncollapse = exports._MoveRefObjectButKeepLinkToWrapper = exports._CheckIfExecutedInIframe = exports._MoveRefIdButKeepLinkToWrapper = void 0;
/**
 * Method moves refObject to safe space but keeps link to wrapper or parent element
 * @param refObjectId
 * @param wrapper if not set = using parent
 */
var _MoveRefIdButKeepLinkToWrapper = function (refObjectId, wrapper) {
    if ($(refObjectId).length) {
        (0, exports._MoveRefObjectButKeepLinkToWrapper)($(refObjectId)[0], wrapper);
    }
};
exports._MoveRefIdButKeepLinkToWrapper = _MoveRefIdButKeepLinkToWrapper;
/**
 * If true the current context is inside an iframe
 * @returns
 */
var _CheckIfExecutedInIframe = function () {
    try {
        return window.self !== window.top;
    }
    catch (e) {
        return true;
    }
};
exports._CheckIfExecutedInIframe = _CheckIfExecutedInIframe;
/**
 * Method moves refObject to safe space but keeps link to wrapper or parent element
 * @param refObject
 * @param wrapper if not set = using parent
 */
var _MoveRefObjectButKeepLinkToWrapper = function (refObject, wrapper) {
    if (!refObject['wrapperLink']) {
        refObject['wrapperLink'] = wrapper || $(refObject).parent();
    }
    $(refObject).appendTo('body'); // ref object to safe place
    refObject.style.cssText += 'display: none !important';
};
exports._MoveRefObjectButKeepLinkToWrapper = _MoveRefObjectButKeepLinkToWrapper;
var _CollapseAndUncollapse = function () {
    // Initially set all elements with data-collapse="true" to height 0
    $("[data-collapse='true']").css('height', '0');
    $("[data-collapse='true']").css('overflow', 'hidden');
    // Click event for elements with data-action="collapse"
    $("[data-action='collapse']").click(function () {
        // Find sibling elements with data-collapse="true" within the parent
        var collapseElements = $(this).parent().find("[data-collapse='true']");
        // Toggle the height
        collapseElements.each(function () {
            var el = $(this);
            if (el.height() === 0) {
                // Animate to the required height
                var autoHeight = el.css('height', 'auto').outerHeight();
                el.height(0).animate({ height: autoHeight }, 500, function () {
                    el.css('height', 'auto');
                });
            }
            else {
                // Animate back to 0 height
                el.animate({ height: '0' }, 500);
            }
        });
    });
};
exports._CollapseAndUncollapse = _CollapseAndUncollapse;
var _DataHide = function () {
    var hideCallback = function () {
        var el = $(this);
        if ($('#' + el.attr('data-hide'))) {
            $('#' + el.attr('data-hide')).css('display', 'none');
        }
    };
    $('[data-hide]').on('click', hideCallback);
    $('[data-hide]').each(hideCallback);
};
exports._DataHide = _DataHide;
var _DiffTimes = function (from, to) {
    var _a;
    var diffTime = Math.abs(from.valueOf() - to.valueOf());
    var days = diffTime / (24 * 60 * 60 * 1000);
    var hours = (days % 1) * 24;
    var minutes = (hours % 1) * 60;
    var secs = (minutes % 1) * 60;
    _a = [
        Math.floor(days),
        Math.floor(hours),
        Math.floor(minutes),
        Math.floor(secs),
    ], days = _a[0], hours = _a[1], minutes = _a[2], secs = _a[3];
    console.log(days + 'd', hours + 'h', minutes + 'm', secs + 's');
    return { days: days, hours: hours, minutes: minutes, secs: secs };
};
exports._DiffTimes = _DiffTimes;
var _RoundUpToTwoDecimals = function (num) {
    return Math.ceil(num * 100) / 100;
};
exports._RoundUpToTwoDecimals = _RoundUpToTwoDecimals;
var _SimpleUUID = function () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0, v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};
exports._SimpleUUID = _SimpleUUID;
var _CalculateAOV = function (term) {
    var _a, _b, _c;
    var aov = { total: 0, fees: 0, subscription: 0, modules: 0 };
    if ((_a = term === null || term === void 0 ? void 0 : term.priceAdjustmentRules) === null || _a === void 0 ? void 0 : _a.length) {
        term = _ConvertPriceAdjustmentRules(term);
        //number of payments of complete term
        var numberOfPayments_1 = term.termValue / term.paymentFrequencyValue;
        var priceAdjustedValue_1 = 0, numberOfPaymentsWithoutAdjustment_1 = numberOfPayments_1;
        term.convertedPriceAdjustmentRules.forEach(function (a, index) {
            if (a.recurrenceUnit === term.termUnit) {
                //priceadjustments from ML have no "timespan" they only have a "after x month" new price applied
                //so we have to calculate the timespans of each priceadjustment and multiply it with their price
                var upperLimitOfPayments_1 = 0;
                term.convertedPriceAdjustmentRules.slice(index + 1).forEach(function (b) {
                    upperLimitOfPayments_1 += numberOfPayments_1 - +b.recurrenceFrequency;
                });
                a.numberOfPayments =
                    numberOfPayments_1 - a.recurrenceFrequency - upperLimitOfPayments_1;
                priceAdjustedValue_1 += a.numberOfPayments * a.value;
                numberOfPaymentsWithoutAdjustment_1 -= a.numberOfPayments;
            }
        });
        aov.subscription =
            priceAdjustedValue_1 + numberOfPaymentsWithoutAdjustment_1 * term.price;
    }
    else {
        //calculate value of subscription
        var paymentIntervalMapping = {
            YEAR: 1 / 12,
            MONTH: 1,
            WEEK: 4.34524,
            DAY: 30.4167,
        };
        if (paymentIntervalMapping[term.paymentFrequencyUnit] &&
            paymentIntervalMapping[term.termUnit]) {
            //change contract duration and payment frequency to monthly
            var paymentIntervalPerMonth = term.paymentFrequencyValue *
                paymentIntervalMapping[term.paymentFrequencyUnit];
            var contractDurationInMonth = term.termValue * paymentIntervalMapping[term.termUnit];
            var numberOfPayments = contractDurationInMonth * paymentIntervalPerMonth;
            aov.subscription = numberOfPayments * term.price;
        }
        else {
            console.error('NO mapping interval', term.paymentFrequencyUnit, paymentIntervalMapping);
        }
    }
    //calculate value of fees
    if ((_b = term === null || term === void 0 ? void 0 : term.flatFees) === null || _b === void 0 ? void 0 : _b.length) {
        for (var _i = 0, _d = term.flatFees; _i < _d.length; _i++) {
            var fee = _d[_i];
            if (fee.paymentFrequencyType === 'NON_RECURRING') {
                aov.fees += fee.price;
            }
            else if (fee.paymentFrequencyType === 'RECURRING' &&
                fee.paymentFrequencyValue &&
                term.termUnit === fee.paymentFrequencyUnit) {
                var numberOfRecurringPayments = term.termValue / fee.paymentFrequencyValue;
                aov.fees += fee.price * numberOfRecurringPayments;
            }
        }
    }
    //calculate value for optionalmodules (same code for flatfees (remove))
    if ((_c = term === null || term === void 0 ? void 0 : term.optionalModules) === null || _c === void 0 ? void 0 : _c.length) {
        for (var _e = 0, _f = term.optionalModules; _e < _f.length; _e++) {
            var module_1 = _f[_e];
            if (module_1.paymentFrequencyType === 'NON_RECURRING') {
                aov.modules += module_1.price;
            }
            else if (module_1.paymentFrequencyType === 'RECURRING' &&
                module_1.paymentFrequencyValue &&
                term.termUnit === module_1.paymentFrequencyUnit) {
                var numberOfRecurringPayments = term.termValue / module_1.paymentFrequencyValue;
                aov.modules += module_1.price * numberOfRecurringPayments;
            }
        }
    }
    aov.total = aov.fees + aov.modules + aov.subscription;
    for (var key in aov) {
        aov[key] = (0, exports._RoundUpToTwoDecimals)(aov[key]);
    }
    return aov;
};
exports._CalculateAOV = _CalculateAOV;
/**
 * Magicline pricadjustment objects are trash and have only contextual strings.
 * Therefore we convert it to more machine readable objects.
 * @param term
 * @returns
 */
var _ConvertPriceAdjustmentRules = function (term) {
    var priceAdjustments = term.priceAdjustmentRules.map(function (a, index) {
        var _a;
        var mapping = {
            woche: 'WEEK',
            monat: 'MONTH',
            jahr: 'YEAR',
            tag: 'DAY',
        };
        var matches = a.recurrenceFrequency.match(/(Nach|Alle) (\d+) (woche|monat|jahr|tag)/i);
        if ((matches === null || matches === void 0 ? void 0 : matches.length) < 3) {
            console.error('Invalid input format', a);
            return undefined;
        }
        if ((matches === null || matches === void 0 ? void 0 : matches.length) > 2) {
            var frequency = matches[1], unit = matches[2];
            var wrongFormat = true;
            for (var key in mapping) {
                if (unit.toLowerCase().includes(key)) {
                    unit = mapping[key];
                    wrongFormat = false;
                }
            }
            var value = 0;
            if ((_a = a.value.match(/\d+[\.|\,]\d\d/)) === null || _a === void 0 ? void 0 : _a.length) {
                value = +a.value.replace(',', '.').match(/\d+\.\d\d/)[0];
                wrongFormat = false;
            }
            if (wrongFormat === true) {
                return undefined;
            }
            return {
                recurrenceUnit: unit,
                recurrenceFrequency: +frequency,
                value: value,
            };
        }
        else {
            return undefined;
        }
    });
    //hotfix fitnesspoint has adjusted price adjustment rules
    if (priceAdjustments === null || priceAdjustments === void 0 ? void 0 : priceAdjustments.length) {
        priceAdjustments = priceAdjustments.filter(function (a) { return a; });
    }
    term.convertedPriceAdjustmentRules = priceAdjustments;
    return term;
};
var _ScrollToTop = function (offset, duration) {
    if (offset === void 0) { offset = 180; }
    if (duration === void 0) { duration = 750; }
    $('html, body').animate({
        scrollTop: $('body').offset().top - offset,
    }, duration);
};
exports._ScrollToTop = _ScrollToTop;
var _ScrollToPageElementOffset = function ($container, elementIdentifier, offset, duration) {
    if (offset === void 0) { offset = 180; }
    if (duration === void 0) { duration = 750; }
    var pageScroll = document.documentElement.scrollTop || document.body.scrollTop;
    console.log('helper pageScroll', elementIdentifier, offset, duration, pageScroll);
    if ($container.find(elementIdentifier).length &&
        (pageScroll > offset + 50 || pageScroll < offset - 50)) {
        $('html, body').animate({
            scrollTop: $container.find(elementIdentifier).offset().top - offset,
        }, duration);
    }
};
exports._ScrollToPageElementOffset = _ScrollToPageElementOffset;
