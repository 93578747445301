"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MyContractsModule = void 0;
var fitpos_api_1 = require("../../../api/fitpos-api");
var magicline_api_1 = require("../../../api/magicline-api");
var input_output_helper_const_1 = require("../../../const/input-output-helper.const");
var input_mapping_const_1 = require("../../../const/input.mapping.const");
var error_handling_1 = require("../../../error-handling");
var helper_1 = require("../../../helper");
var object_service_1 = require("../../../object-service");
var settings_service_1 = require("../../../services/settings.service");
var mappings_const_1 = require("../const/mappings.const");
var data_processing_service_1 = require("../services/data-processing.service");
var MyContractsModule = /** @class */ (function () {
    function MyContractsModule() {
        this.config = object_service_1.ObjectService.getInstance(settings_service_1.SettingsService);
        this.magiclineApi = object_service_1.ObjectService.getInstance(magicline_api_1.MagiclineApi);
        this.fitposAPI = object_service_1.ObjectService.getInstance(fitpos_api_1.FitposApi);
        this.errorHandler = object_service_1.ObjectService.getInstance(error_handling_1.ErrorHandling);
        this.helper = object_service_1.ObjectService.getInstance(helper_1.Helper);
        this.dataProcessing = object_service_1.ObjectService.getInstance(data_processing_service_1.DataProcessing);
        this.emptyMessage = 'There are no contracts for this user';
    }
    MyContractsModule.prototype.loadModule = function (customer, moduleDiv) {
        return __awaiter(this, void 0, void 0, function () {
            var studioId, contracts, offerings, error_1, refObjectId, _loop_1, this_1, _i, contracts_1, contract;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        studioId = (0, input_output_helper_const_1._ReadFieldValue)(input_mapping_const_1._AllMappings.studioId);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, this.fitposAPI.getCustomerContractData(customer.id, 'ACTIVE')];
                    case 2:
                        contracts = _a.sent();
                        return [4 /*yield*/, this.magiclineApi.getOfferings(studioId)];
                    case 3:
                        offerings = _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        error_1 = _a.sent();
                        this.errorHandler.showErrorMessage(mappings_const_1._Messages.GetError);
                        // disable inputs etc.?
                        console.error(error_1);
                        return [3 /*break*/, 5];
                    case 5:
                        refObjectId = '#' + this.config.get.fitpos.refObjectMyContracts;
                        if (contracts.length) {
                            moduleDiv.setAttribute(MyContractsModule.selected, contracts[0].id.toString());
                            // TODO styling
                        }
                        else {
                            $(refObjectId).css('display', 'none');
                            $(refObjectId).parent().append(this.emptyMessage);
                        }
                        _loop_1 = function (contract) {
                            // const rate = offerings.find(offering => offering.name === contract.rateName);
                            // //needed for output:
                            // contract.description = rate.description;
                            // contract.subDescription = rate.subDescription;
                            var clone = this_1.helper.createClonesForRefObjectId([{ key: 'data-id', value: contract.id.toString() }], refObjectId); // TODO restrict to inside of moduleDiv
                            if (clone) {
                                this_1.dataProcessing.fillDataGet(clone[0], contract);
                            }
                            $(refObjectId).parent().append(clone);
                            clone.on('click', function () {
                                moduleDiv.setAttribute(MyContractsModule.selected, contract.id.toString());
                                // $(moduleDiv).attr(MyContractsModule.selected, contracts[0].id.toString());
                            }); // TODO styling
                        };
                        this_1 = this;
                        for (_i = 0, contracts_1 = contracts; _i < contracts_1.length; _i++) {
                            contract = contracts_1[_i];
                            _loop_1(contract);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    MyContractsModule.selected = 'data-selected';
    return MyContractsModule;
}());
exports.MyContractsModule = MyContractsModule;
