"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports._AssetFolder = exports._CloudFunctions = exports._CloudFunctionEndpoint = void 0;
exports._CloudFunctionEndpoint = 'https://europe-west3-named-catcher-373207.cloudfunctions.net';
exports._CloudFunctions = {
    leads: exports._CloudFunctionEndpoint + '/leads',
    sendcloud: exports._CloudFunctionEndpoint + '/sendcloud',
    stripe: exports._CloudFunctionEndpoint + '/stripe-public-endpoint',
};
exports._AssetFolder = 'https://files.scaleyourgym.com/files/webflow/new-magicline/assets';
