"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Fitpos = void 0;
var fitpos_api_1 = require("../../api/fitpos-api");
var module_mapping_const_1 = require("../../const/module-mapping.const");
var error_handling_1 = require("../../error-handling");
var helper_1 = require("../../helper");
var object_service_1 = require("../../object-service");
var settings_service_1 = require("../../services/settings.service");
var mappings_const_1 = require("./const/mappings.const");
var storage_service_1 = require("./services/storage.service");
var Fitpos = /** @class */ (function () {
    function Fitpos() {
        this.fitposAPI = object_service_1.ObjectService.getInstance(fitpos_api_1.FitposApi);
        // private changeDataModule = ObjectService.getInstance(ChangeDataModule);
        this.errorHandler = object_service_1.ObjectService.getInstance(error_handling_1.ErrorHandling);
        this.storageService = object_service_1.ObjectService.getInstance(storage_service_1.StorageService);
        this.config = object_service_1.ObjectService.getInstance(settings_service_1.SettingsService);
        // private readonly customerId = 1224381801;
        this.styles = false;
    }
    Fitpos.prototype.loadModule = function () {
        return __awaiter(this, void 0, void 0, function () {
            var customer, customerId, error_1, promises;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log('FITPOS');
                        object_service_1.ObjectService.getInstance(helper_1.Helper).createLoadingScreen();
                        customerId = this.storageService.parseUrlParams();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.fitposAPI.getCustomerById(customerId)];
                    case 2:
                        customer = _a.sent();
                        console.log('CUSTOMER IMPORTANT', customer, this.config);
                        if (customer === null || customer === void 0 ? void 0 : customer.studioId) {
                            this.config.update('studioId', customer.studioId);
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        this.errorHandler.showErrorMessage(mappings_const_1._Messages.GetError);
                        // disable inputs etc.?
                        console.error('NO CUSTOMER', error_1);
                        return [3 /*break*/, 4];
                    case 4:
                        if (this.styles === false) {
                            $(document).ready(function () {
                                // Function to get the query parameter value by name
                                function getQueryParam(name) {
                                    var urlParams = new URLSearchParams(window.location.search);
                                    var primaryColor = urlParams.get(name);
                                    if (primaryColor === null || primaryColor === void 0 ? void 0 : primaryColor.length) {
                                        localStorage.setItem('primaryColor', primaryColor);
                                    }
                                    else {
                                        primaryColor = localStorage.getItem('primaryColor');
                                    }
                                    return primaryColor;
                                }
                                // Get the 'primaryColor' from the URL
                                var newBrandingColor = '#' + (getQueryParam('primaryColor') || 'f4e113');
                                $(':root').css('--primary', newBrandingColor);
                                $('.gradient-bg').css('background-image', 'linear-gradient(' + newBrandingColor + 33 + ', #f4e11300)');
                            });
                            this.styles = true;
                        }
                        promises = [];
                        $('[data-module]').each(function (i, element) {
                            var module = module_mapping_const_1._FitposMapping[$(element).attr('data-module')];
                            var promise = module.loadModule(customer, element);
                            promises.push(promise);
                        });
                        Promise.all(promises).then(function () {
                            object_service_1.ObjectService.getInstance(helper_1.Helper).deleteLoadingScreen();
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    return Fitpos;
}());
exports.Fitpos = Fitpos;
