"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports._ScrollToFirstErrorField = exports._AddSuccessToFormField = exports._AddSuccessToDomObject = exports._AddToastMessage = exports._AddErrorToDomObject = exports._AddErrorToFormField = exports._RemoveSuccessFromDomObject = exports._RemoveErrorFromDomObject = exports._RemoveStatusFromDomObject = exports._RemoveStatusFromFormField = exports._RemoveSuccessFromFormField = exports._RemoveErrorFromFormField = void 0;
var css_injector_service_1 = require("../services/css-injector.service");
var custom_dom_names_const_1 = require("./custom-dom-names.const");
var helper_const_1 = require("./helper.const");
var _RemoveErrorFromFormField = function (formField, $container) {
    if ($container === void 0) { $container = $('body'); }
    $container.find(formField.uid).css('border', '').css('background', '');
    $container.find('#error-' + formField.uid.replace('#', '')).remove();
};
exports._RemoveErrorFromFormField = _RemoveErrorFromFormField;
var _RemoveSuccessFromFormField = function (formField, $container) {
    if ($container === void 0) { $container = $('body'); }
    $container.find(formField.uid).css('border', '').css('background', '');
    $container.find('#success-' + formField.uid.replace('#', '')).remove();
};
exports._RemoveSuccessFromFormField = _RemoveSuccessFromFormField;
var _RemoveStatusFromFormField = function (formField, $container) {
    if ($container === void 0) { $container = $('body'); }
    (0, exports._RemoveErrorFromFormField)(formField, $container);
    (0, exports._RemoveSuccessFromFormField)(formField, $container);
};
exports._RemoveStatusFromFormField = _RemoveStatusFromFormField;
/**
 *
 * @param domObject Input field we add message to
 */
var _RemoveStatusFromDomObject = function (domObject) {
    (0, exports._RemoveErrorFromDomObject)(domObject);
    (0, exports._RemoveSuccessFromDomObject)(domObject);
};
exports._RemoveStatusFromDomObject = _RemoveStatusFromDomObject;
var _RemoveErrorFromDomObject = function (domObject) {
    domObject.css('border', '').css('background', '');
    domObject.parent().find(".".concat(custom_dom_names_const_1._ClassNames.errorfield)).remove();
};
exports._RemoveErrorFromDomObject = _RemoveErrorFromDomObject;
var _RemoveSuccessFromDomObject = function (domObject) {
    domObject.css('border', '').css('background', '');
    domObject.parent().find(".".concat(custom_dom_names_const_1._ClassNames.successfield)).remove();
};
exports._RemoveSuccessFromDomObject = _RemoveSuccessFromDomObject;
var _AddErrorToFormField = function (formField, $container, errorMessage) {
    if ($container === void 0) { $container = $('body'); }
    (0, exports._RemoveStatusFromFormField)(formField);
    $container.find(formField.uid).after($("<span \n            style=\"color:red\" \n            class=\"".concat(custom_dom_names_const_1._ClassNames.errorfield, "\" \n            id=\"error-").concat(formField.uid.replace('#', ''), "\" \n            data-link=\"").concat(formField.uid, "\">\n          *").concat(errorMessage || 'Dieses Feld erforderlich.', "\n        </span>")));
    if ($container.find(formField.uid).is(':input')) {
        $container.find(formField.uid).css('border', 'red 1px solid');
    }
};
exports._AddErrorToFormField = _AddErrorToFormField;
var _AddErrorToDomObject = function (domObject, errorMessage) {
    if (domObject.length) {
        (0, exports._RemoveStatusFromDomObject)(domObject);
        domObject.after($("<span \n            style=\"color:red\" \n            class=\"".concat(custom_dom_names_const_1._ClassNames.errorfield, "\" >\n          *").concat(errorMessage || 'Dieses Feld erforderlich.', "\n        </span>")));
        if (domObject.is(':input')) {
            domObject.css('border', 'red 1px solid');
        }
    }
    else {
        console.error('Error field doesnt exist', errorMessage, domObject);
    }
};
exports._AddErrorToDomObject = _AddErrorToDomObject;
var toasts = [];
var _AddToastMessage = function (domObject, message, settings) {
    if (domObject.length) {
        css_injector_service_1.CssInjectorService.injectCss('_AddToastMessage', "\n      .".concat(custom_dom_names_const_1._ClassNames.toastmessage, "{\n        position: fixed;\n        width: 30%;\n        top: -50px;\n        left: 35%;\n        background-color: red;\n        height: 0;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        text-align: center;\n        z-index: 999;\n        overflow: hidden;\n        transition: all .2s;\n        animation: fittec-expand 0.2s ease-out 0.1s forwards;\n      }\n      @keyframes fittec-expand {\n        0% {\n          width: 0;\n          left: 35%;\n          height: 0;\n        }\n        100% {\n          width: 90%;\n          left: 5%;\n          height: 50px;\n        }\n      }\n    ") + ((settings === null || settings === void 0 ? void 0 : settings.cssOverwrite) || ''));
        var removeToast = function () {
            toast_1.css('top', '-55px');
            setTimeout(function () {
                toast_1.remove();
                toasts.splice(toasts.findIndex(function (a) { return a === toast_1; }), 1);
                toasts.forEach(function (a, index) { a.css('top', (index * 55) + 'px'); });
            }, 200);
        };
        var toast_1 = $("<div class=\"".concat(custom_dom_names_const_1._ClassNames.toastmessage, "\" data-ref=\"fittec-toast-entry\">\n          <span>").concat(message || 'Dieses Feld erforderlich.', "</span>\n        </div>"));
        if (settings.closeable) {
            toast_1.append($("<span > X </span>").on('click', removeToast));
        }
        domObject.append(toast_1);
        toast_1.css('top', (toasts.length * 55) + 'px');
        toasts.push(toast_1);
        if (settings === null || settings === void 0 ? void 0 : settings.timer) {
            setTimeout(removeToast, settings.timer);
        }
    }
    else {
        console.error('Error field doesnt exist', message, domObject);
    }
};
exports._AddToastMessage = _AddToastMessage;
var _AddSuccessToDomObject = function (domObject, successMessage) {
    if (domObject === void 0) { domObject = $('body'); }
    (0, exports._RemoveStatusFromDomObject)(domObject);
    if (successMessage === undefined || (successMessage === null || successMessage === void 0 ? void 0 : successMessage.length)) {
        domObject.after($("<span \n              style=\"color:#058405\" \n              class=\"".concat(custom_dom_names_const_1._ClassNames.successfield, "\">\n            ").concat(successMessage !== null && successMessage !== void 0 ? successMessage : '💪 Sieht gut aus!', " \n          </span>")));
    }
    if (domObject.is(':input')) {
        domObject
            .css('border', '#058405 2px solid')
            .css('background', '#c1e8c1')
            .css('background', '#222');
    }
};
exports._AddSuccessToDomObject = _AddSuccessToDomObject;
var _AddSuccessToFormField = function (formField, $container, successMessage) {
    if ($container === void 0) { $container = $('body'); }
    (0, exports._RemoveStatusFromFormField)(formField);
    $container.find(formField.uid).after($("<span \n            style=\"color:#058405\" \n            class=\"".concat(custom_dom_names_const_1._ClassNames.successfield, "\" \n            id=\"success-").concat(formField.uid.replace('#', ''), "\" \n            data-link=\"").concat(formField.uid, "\">\n          \uD83D\uDCAA Sieht gut aus!\n        </span>")));
    if ($container.find(formField.uid).is(':input')) {
        $container
            .find(formField.uid)
            .css('border', '#058405 2px solid')
            .css('background', '#c1e8c1');
    }
};
exports._AddSuccessToFormField = _AddSuccessToFormField;
var _ScrollToFirstErrorField = function ($container) {
    if ($container.find(".".concat(custom_dom_names_const_1._ClassNames.errorfield)).length) {
        var link = $container
            .find(".".concat(custom_dom_names_const_1._ClassNames.errorfield))
            .first()
            .attr('data-link');
        if (link) {
            (0, helper_const_1._ScrollToPageElementOffset)($container, link);
        }
    }
};
exports._ScrollToFirstErrorField = _ScrollToFirstErrorField;
