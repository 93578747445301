"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BotChoice = void 0;
var object_service_1 = require("../../object-service");
var settings_service_1 = require("../../services/settings.service");
var bot_const_1 = require("./bot.const");
var BotChoice = /** @class */ (function () {
    function BotChoice() {
        this.config = object_service_1.ObjectService.getInstance(settings_service_1.SettingsService);
    }
    Object.defineProperty(BotChoice.prototype, "bot", {
        get: function () {
            return this === null || this === void 0 ? void 0 : this._botChoice;
        },
        set: function (bot) {
            this._botChoice = bot;
        },
        enumerable: false,
        configurable: true
    });
    BotChoice.prototype.loadModule = function (gptContainer, selectedBot) {
        return __awaiter(this, void 0, void 0, function () {
            var botContainer, title, layoutContainer, _loop_1, _i, _Bots_1, bot;
            var _this = this;
            var _a;
            return __generator(this, function (_b) {
                this.bot = this.findBot((_a = this.config.get.chatgpt.botDefault) !== null && _a !== void 0 ? _a : 'casual');
                botContainer = $('<div class="bot-container"></div>');
                title = $('<h4>Wähle deinen Helfer</h4>');
                title.css({ margin: '15px 0 0 0' });
                layoutContainer = $('<div class="layout-container"></div>');
                _loop_1 = function (bot) {
                    var div = $("<div class=\"inner-div\" title=\"".concat(bot.title, "\">\n        <img class=\"bot-image\" src=\"").concat(bot.assetImage, "\">\n        <span>").concat(bot.displayName, "</span>\n      </div>"));
                    div.on('click', function () {
                        _this._botChoice = bot;
                        console.log(bot);
                        selectedBot(bot);
                    });
                    layoutContainer.append(div);
                };
                for (_i = 0, _Bots_1 = bot_const_1._Bots; _i < _Bots_1.length; _i++) {
                    bot = _Bots_1[_i];
                    _loop_1(bot);
                }
                botContainer.append(title);
                botContainer.append(layoutContainer);
                gptContainer.prepend(botContainer);
                return [2 /*return*/];
            });
        });
    };
    BotChoice.prototype.findBot = function (botSelection) {
        return bot_const_1._Bots.find(function (a) { return a.type === botSelection; });
    };
    return BotChoice;
}());
exports.BotChoice = BotChoice;
