"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventListeners = void 0;
var object_service_1 = require("../../object-service");
var form_const_1 = require("./const/form.const");
var price_preview_1 = require("../price-preview/price-preview");
var EventListeners = /** @class */ (function () {
    function EventListeners() {
        this.magiclinePaymentChoice = 'DIRECT_DEBIT';
        this.priceOverview = object_service_1.ObjectService.getInstance(price_preview_1.PricePreview);
    }
    // private helper = ObjectService.getInstance(Helper);
    EventListeners.prototype.addEvents = function () {
        $('input[name="membershipId"]').on('click', function (event) {
            var inputElement = event.target;
            if (inputElement === null || inputElement === void 0 ? void 0 : inputElement.value) {
                localStorage.setItem(form_const_1.MAPPINGS.membershipId.localStorageKey, inputElement.value);
                //TODO: IS THAT REQUIRED HERE?
                // this.priceOverview.loadModule();
            }
        });
        $(form_const_1.MAPPINGS.zipCode.id).on('input', function (event) {
            event.target.value = event.target.value
                .replace(/[^0-9]/g, '')
                .substring(0, 5);
        });
        $(form_const_1.MAPPINGS.telephone_mobile.id).on('input', function (event) {
            event.target.value = event.target.value.replace(/[^0-9]/g, '');
        });
        //Prepare input fields with e.g. event handlers. They will only be applied if the input exists.
        if ($(form_const_1.MAPPINGS.iban.id).length &&
            typeof $(form_const_1.MAPPINGS.iban.id).inputmask === 'function') {
            $(form_const_1.MAPPINGS.iban.id).inputmask({
                mask: 'AA99 9999 9999 9999 9999 99',
                clearMaskOnLostFocus: false,
            });
        }
    };
    return EventListeners;
}());
exports.EventListeners = EventListeners;
