"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports._ModuleMapping = exports._FMSMapping = exports._FitposMapping = void 0;
var object_service_1 = require("../object-service");
var checkout_1 = require("../modules/checkout/checkout");
var form_1 = require("../modules/finish/form");
var price_preview_1 = require("../modules/price-preview/price-preview");
var available_contracts_module_1 = require("../modules/fitpos/modules/available-contracts.module");
var cancel_contracts_module_1 = require("../modules/fitpos/modules/cancel-contracts.module");
var change_data_module_1 = require("../modules/fitpos/modules/change-data.module");
var customer_data_module_1 = require("../modules/fitpos/modules/customer-data.module");
var my_contracts_module_1 = require("../modules/fitpos/modules/my-contracts.module");
var referrals_module_1 = require("../modules/fitpos/modules/referrals.module");
var thank_you_module_1 = require("../modules/fitpos/modules/thank-you.module");
var lead_form_1 = require("../modules/lead-form/lead-form");
var location_1 = require("../modules/location/location");
var missing_payment_1 = require("../modules/stripe/missing-payment");
var profile_edit_1 = require("../modules/success/profile-edit");
var keycloak_form_1 = require("../modules/sso/keycloak-form");
var manage_modules_module_1 = require("../modules/fitpos/modules/manage-modules.module");
var trial_booking_module_1 = require("../modules/trial-booking/trial-booking.module");
var my_modules_module_1 = require("../modules/fitpos/modules/my-modules.module");
exports._FitposMapping = {
    'change-data': object_service_1.ObjectService.getInstance(change_data_module_1.ChangeDataModule),
    'my-contracts': object_service_1.ObjectService.getInstance(my_contracts_module_1.MyContractsModule),
    'available-contracts': object_service_1.ObjectService.getInstance(available_contracts_module_1.AvailableContractsModule),
    'available-modules': object_service_1.ObjectService.getInstance(available_contracts_module_1.AvailableContractsModule),
    'customer-data': object_service_1.ObjectService.getInstance(customer_data_module_1.CustomerDataModule),
    referrals: object_service_1.ObjectService.getInstance(referrals_module_1.ReferralsModule),
    'cancel-contract': object_service_1.ObjectService.getInstance(cancel_contracts_module_1.CancelContractsModule),
    'thank-you': object_service_1.ObjectService.getInstance(thank_you_module_1.ThankYouModule),
    'manage-modules': object_service_1.ObjectService.getInstance(manage_modules_module_1.ManageModulesModule),
    'my-modules': object_service_1.ObjectService.getInstance(my_modules_module_1.MyModules),
};
exports._FMSMapping = {
    checkout: object_service_1.ObjectService.getInstance(checkout_1.Checkout),
    modules: object_service_1.ObjectService.getInstance(checkout_1.Checkout),
    'profile-edit-fms': object_service_1.ObjectService.getInstance(profile_edit_1.ProfileEdit),
    location: object_service_1.ObjectService.getInstance(location_1.Location),
    'lead-form': object_service_1.ObjectService.getInstance(lead_form_1.LeadForm),
    'missing-stripe-payment': object_service_1.ObjectService.getInstance(missing_payment_1.MissingPayment),
    'price-preview': object_service_1.ObjectService.getInstance(price_preview_1.PricePreview),
    'final-form': object_service_1.ObjectService.getInstance(form_1.Form),
    'keycloak-form': object_service_1.ObjectService.getInstance(keycloak_form_1.KeycloakForm),
    'trial-booking': object_service_1.ObjectService.getInstance(trial_booking_module_1.TrialBookingModule),
};
exports._ModuleMapping = __assign(__assign({}, exports._FitposMapping), exports._FMSMapping);
