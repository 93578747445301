"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CssInjectorService = void 0;
var CssInjectorService = /** @class */ (function () {
    function CssInjectorService() {
    }
    CssInjectorService.injectCss = function (className, style) {
        if (!CssInjectorService.injectedClasses[className]) {
            var styleSheet = document.createElement('style');
            styleSheet.type = 'text/css';
            styleSheet.innerText = style;
            document.head.appendChild(styleSheet);
            CssInjectorService.injectedClasses[className] = true;
        }
    };
    CssInjectorService.injectedClasses = {};
    return CssInjectorService;
}());
exports.CssInjectorService = CssInjectorService;
