"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Index = void 0;
require("reflect-metadata");
var PACKAGE = __importStar(require("../package.json"));
var helper_const_1 = require("./const/helper.const");
var module_mapping_const_1 = require("./const/module-mapping.const");
var helper_1 = require("./helper");
var object_service_1 = require("./object-service");
var checkout_1 = require("./modules/checkout/checkout");
var form_1 = require("./modules/finish/form");
var fitpos_1 = require("./modules/fitpos/fitpos");
var lead_form_1 = require("./modules/lead-form/lead-form");
var location_1 = require("./modules/location/location");
var micro_components_1 = require("./modules/micro-components/micro-components");
var price_preview_1 = require("./modules/price-preview/price-preview");
var keycloak_1 = require("./modules/sso/keycloak");
var missing_payment_1 = require("./modules/stripe/missing-payment");
var stripe_payment_1 = require("./modules/stripe/stripe-payment");
var profile_edit_1 = require("./modules/success/profile-edit");
var settings_service_1 = require("./services/settings.service");
var tracker_service_1 = require("./services/tracker.service");
require("./translation");
var chatgpt_1 = require("./widgets/chatgpt/chatgpt");
// let container = new Container();
var Index = /** @class */ (function () {
    function Index(settings) {
        var _a;
        this.config = object_service_1.ObjectService.getInstance(settings_service_1.SettingsService);
        this.config.set = settings;
        this.$container = ((_a = this.config.get) === null || _a === void 0 ? void 0 : _a.$container) || $('body');
    }
    Index.prototype.loadModule = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _i, _MicroComponents_1, component;
            var _this = this;
            var _a, _b, _c, _d, _e, _f, _g, _h;
            return __generator(this, function (_j) {
                switch (_j.label) {
                    case 0:
                        object_service_1.ObjectService.getInstance(helper_1.Helper).loadModule(this.$container);
                        object_service_1.ObjectService.getInstance(tracker_service_1.TrackerService).loadModule();
                        if (!(this.config.get && ((_b = (_a = this.config.get) === null || _a === void 0 ? void 0 : _a.tenantKey) === null || _b === void 0 ? void 0 : _b.length))) return [3 /*break*/, 3];
                        console.info("MAGICLINE SCRIPT ".concat(process.env.NODE_ENV.toUpperCase(), ":"), PACKAGE.version, this.config.get.magicLineUrl);
                        for (_i = 0, _MicroComponents_1 = micro_components_1._MicroComponents; _i < _MicroComponents_1.length; _i++) {
                            component = _MicroComponents_1[_i];
                            object_service_1.ObjectService.getInstance(component).loadModule(this.$container);
                        }
                        if (!((_d = (_c = this.config.get) === null || _c === void 0 ? void 0 : _c.stripe) === null || _d === void 0 ? void 0 : _d.stripeKey)) return [3 /*break*/, 2];
                        return [4 /*yield*/, object_service_1.ObjectService.getInstance(stripe_payment_1.StripePayment).loadModule(this.$container)];
                    case 1:
                        _j.sent();
                        _j.label = 2;
                    case 2:
                        if ((_e = this.config.get) === null || _e === void 0 ? void 0 : _e.sso) {
                            object_service_1.ObjectService.getInstance(keycloak_1.SSO).loadModule();
                        }
                        if (this.config.get.section === 'v2') {
                            this.$container.find('[data-module]').each(function (i, element) {
                                //CRIT: CHANGE THIS TO MORE GENERIC AND VERSATILE SOLUTION NOTE SETTINGS OBJECT IS MISSING TOO
                                var module = module_mapping_const_1._FMSMapping[$(element).attr('data-module')];
                                var toCamelCase = function (str) {
                                    return str.replace(/-([a-z])/g, function (g) { return g[1].toUpperCase(); });
                                };
                                var getDataAttributes = function (element) {
                                    var dataAttributes = {};
                                    $.each(element.data(), function (key, value) {
                                        var camelCaseKey = toCamelCase(key);
                                        dataAttributes[camelCaseKey] = value;
                                    });
                                    return dataAttributes;
                                };
                                module.loadModule(_this.$container.find(element), getDataAttributes(_this.$container.find(element)));
                            });
                        }
                        else if (this.config.get.section === 'finish') {
                            object_service_1.ObjectService.getInstance(form_1.Form).loadModule(this.$container);
                            object_service_1.ObjectService.getInstance(price_preview_1.PricePreview).loadModule(this.$container);
                        }
                        else if (this.config.get.section === 'checkout' ||
                            this.config.get.section === 'modules') {
                            object_service_1.ObjectService.getInstance(checkout_1.Checkout).loadModule(this.$container);
                        }
                        else if (this.config.get.section === 'success') {
                            object_service_1.ObjectService.getInstance(profile_edit_1.ProfileEdit).loadModule();
                        }
                        else if (this.config.get.section === 'location') {
                            object_service_1.ObjectService.getInstance(location_1.Location).loadModule();
                        }
                        else if (this.config.get.section === 'leadform') {
                            object_service_1.ObjectService.getInstance(lead_form_1.LeadForm).loadModule();
                        }
                        else if (this.config.get.section === 'fitpos') {
                            object_service_1.ObjectService.getInstance(fitpos_1.Fitpos).loadModule();
                        }
                        else if (this.config.get.section === 'missing-stripe-payment') {
                            object_service_1.ObjectService.getInstance(missing_payment_1.MissingPayment).loadModule(this.$container);
                        }
                        else {
                            console.error('NO SECTION IS DEFINED PLEASE SET SETTINGS.SECTION');
                        }
                        if ((_f = this.config.get.widgets) === null || _f === void 0 ? void 0 : _f.length) {
                            if (this.config.get.widgets.includes('chatgpt')) {
                                object_service_1.ObjectService.getInstance(chatgpt_1.ChatGPT).loadModule();
                            }
                        }
                        if ((_g = this.config.get) === null || _g === void 0 ? void 0 : _g.hideDebugInfos) {
                            console.error('NOTE YOU HAVE HIDDEN ANY DEV HINTS / LOGS ONLY RECOMMENDED FOR E.G. DEMOS');
                        }
                        if (this.config.get.environment === 'dev' &&
                            !((_h = this.config.get) === null || _h === void 0 ? void 0 : _h.hideDebugInfos)) {
                            console.info('Loading specific section: ', this.config.get.section, this.config.get);
                            $('body').prepend('<h4 style="color:red; width:100%; background: #ddd; text-align:center; margin:0;padding:0;">USING DEV SCRIPT FOR TESTING PURPOSES ONLY</h4>');
                            document.title = 'DEV SCRIPT: ' + document.title;
                        }
                        //Methods that are just called once per page
                        (0, helper_const_1._CollapseAndUncollapse)();
                        (0, helper_const_1._DataHide)();
                        return [3 /*break*/, 4];
                    case 3:
                        console.error('Critical error: No Tenantname provided by webflow');
                        _j.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return Index;
}());
exports.Index = Index;
var fittecObj = document === null || document === void 0 ? void 0 : document.fittec;
if (typeof fittecObj === 'undefined') {
    document.addEventListener('DOMContentLoaded', function (event) {
        if ((0, helper_const_1._CheckIfExecutedInIframe)()) {
            console.error('catched iframe');
            document.body.innerHTML = '';
            return;
        }
        new Index(settings).loadModule();
    });
}
else {
    document.fittec.index = function (settings) {
        return new Index(settings);
    };
}
// container.bind<ApiWrapper>(ApiWrapper).toSelf().inSingletonScope();
// container.bind<LeadApi>(LeadApi).toSelf().inSingletonScope();
// const dom = container.resolve(Index);
