"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MissingPayment = void 0;
var infrastructure_const_1 = require("../../const/infrastructure.const");
var input_output_helper_const_1 = require("../../const/input-output-helper.const");
var input_mapping_const_1 = require("../../const/input.mapping.const");
var error_handling_1 = require("../../error-handling");
var helper_1 = require("../../helper");
var object_service_1 = require("../../object-service");
var settings_service_1 = require("../../services/settings.service");
var stripe_payment_1 = require("./stripe-payment");
var MissingPayment = /** @class */ (function () {
    function MissingPayment() {
        this.settings = object_service_1.ObjectService.getInstance(settings_service_1.SettingsService);
        this.helper = object_service_1.ObjectService.getInstance(helper_1.Helper);
        this.stripePayment = object_service_1.ObjectService.getInstance(stripe_payment_1.StripePayment);
        this.errorService = object_service_1.ObjectService.getInstance(error_handling_1.ErrorHandling);
    }
    MissingPayment.prototype.sendForm = function (customer) {
        return __awaiter(this, void 0, void 0, function () {
            var paymentMethodId, stripeRes, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.info('SUBMIT PAYMENT');
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        this.helper.createLoadingScreen();
                        return [4 /*yield*/, this.stripePayment.getPaymentMethodId()];
                    case 2:
                        paymentMethodId = _a.sent();
                        this.helper.deleteLoadingScreen();
                        return [4 /*yield*/, fetch(infrastructure_const_1._CloudFunctions.stripe + '/order', {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json' },
                                body: JSON.stringify(__assign(__assign({}, customer), { paymentMethodId: paymentMethodId, termId: +(0, input_output_helper_const_1._ReadFieldValue)(input_mapping_const_1._AllMappings.rateBundleTermId) })),
                            }).then(function (response) {
                                if (!response.ok) {
                                    throw new Error('Network response was not ok');
                                }
                                return response.text();
                            })];
                    case 3:
                        stripeRes = _a.sent();
                        if (stripeRes === 'success') {
                            $('[data-action="submit"]').prop('disabled', true);
                            this.errorService.showSuccessMessage('Vielen Dank für deine Hilfe!');
                        }
                        else {
                            throw stripeRes;
                        }
                        this.helper.deleteLoadingScreen();
                        console.log('perform stripe request', stripeRes);
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _a.sent();
                        this.errorService.showErrorMessage(typeof e_1 === 'string' ? e_1 : 'Something went wrong with your payment');
                        this.helper.deleteLoadingScreen();
                        return [2 /*return*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    MissingPayment.prototype.loadModule = function ($container) {
        return __awaiter(this, void 0, void 0, function () {
            var queryString, urlParams, customerEncoded, customer_1, attr;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.$container = $container;
                        console.log('Load missing payment');
                        queryString = window.location.search;
                        urlParams = new URLSearchParams(queryString);
                        customerEncoded = urlParams.get('a');
                        if (!customerEncoded) return [3 /*break*/, 4];
                        customer_1 = JSON.parse(decodeURIComponent(atob(decodeURIComponent(customerEncoded))));
                        if (!(customer_1.customerData &&
                            customer_1.accountId &&
                            customer_1.magiclineCustomerId &&
                            customer_1.studioId &&
                            customer_1.tenantKey)) return [3 /*break*/, 2];
                        console.log('customer', customer_1, customerEncoded);
                        for (attr in customer_1.customerData) {
                            $('[data-get="' + attr + '"]').val(customer_1.customerData[attr]);
                            $('[data-get="' + attr + '"]').prop('disabled', true);
                        }
                        this.helper.createLoadingScreen();
                        return [4 /*yield*/, this.stripePayment.loadModule(this.$container)];
                    case 1:
                        _a.sent();
                        this.helper.deleteLoadingScreen();
                        $('[data-action="submit"]').on('click', function (event) { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                this.sendForm(customer_1);
                                return [2 /*return*/];
                            });
                        }); });
                        return [3 /*break*/, 3];
                    case 2:
                        this.errorService.showErrorMessage('Fehler bei der Dekodierung bitte wende dich an den Support.');
                        _a.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        this.errorService.showErrorMessage('Bitte klicke direkt auf den Link in der E-Mail. Falls dieser Fehler nicht verschwindet wende dich bitte an den Support');
                        _a.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    return MissingPayment;
}());
exports.MissingPayment = MissingPayment;
