"use strict";
// SINGLETON DESIGN PATTERN TO HAVE ONLY ONE INSTANCE PER CLASS
Object.defineProperty(exports, "__esModule", { value: true });
exports.ObjectService = void 0;
var ObjectService = /** @class */ (function () {
    function ObjectService() {
    }
    ObjectService.getInstance = function (classType) {
        if (!classType) {
            console.error(classType);
        }
        var className = classType === null || classType === void 0 ? void 0 : classType.name;
        if (!ObjectService.instances[className]) {
            ObjectService.instances[className] = new classType();
        }
        return ObjectService.instances[className];
    };
    ObjectService.instances = {};
    return ObjectService;
}());
exports.ObjectService = ObjectService;
