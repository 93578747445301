"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetEndpoint = exports.PostEndpoint = void 0;
var PostEndpoint;
(function (PostEndpoint) {
    PostEndpoint["CustomerAmendment"] = "/customer-amendment";
    PostEndpoint["ContractCancelation"] = "/cancel-contract";
    PostEndpoint["PurchaseModule"] = "/purchase-module";
})(PostEndpoint || (exports.PostEndpoint = PostEndpoint = {}));
var GetEndpoint;
(function (GetEndpoint) {
    GetEndpoint["Contracts"] = "/contracts";
    GetEndpoint["PurchasableModules"] = "/purchasable-modules";
    GetEndpoint["Customer"] = "/customer";
    GetEndpoint["CancellationReason"] = "/cancelation-reasons";
})(GetEndpoint || (exports.GetEndpoint = GetEndpoint = {}));
