"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports._Bots = void 0;
var infrastructure_const_1 = require("../../const/infrastructure.const");
exports._Bots = [
    {
        displayName: 'Lea',
        assetImage: infrastructure_const_1._AssetFolder + '/ai/polite.png',
        type: 'friendly',
        title: 'Unformal, locker und herzlich - einfach ein cooler typ',
    },
    {
        displayName: 'Bastian',
        assetImage: infrastructure_const_1._AssetFolder + '/ai/casual.png',
        type: 'formal',
        title: 'Absolut professionel, hilfsbereit und höflich',
    },
];
