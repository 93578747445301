"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CiComponentInjectorService = void 0;
var CiComponentInjectorService = /** @class */ (function () {
    function CiComponentInjectorService() {
        this.hasBeenLoaded = false;
    }
    CiComponentInjectorService.prototype.loadScript = function () {
        if (this.hasBeenLoaded === false) {
            var globalConfig = $('<script>', {
                text: "\n        document.corporateIdentity = {\n          allowChangeTheme: false,\n          defaultTheme: 'light',\n          selectedTheme: 'light',\n          relativePath: '/files/new-webcomponents/dist/corpident/shared',\n        };",
            });
            $('body').append(globalConfig);
            var scriptElement = $('<script>', {
                type: 'module',
                text: "import { defineCustomElements } from 'https://files.scaleyourgym.com/files/new-webcomponents/loader/index.es2017.js'; defineCustomElements();",
            });
            $('body').append(scriptElement);
            this.hasBeenLoaded = true;
        }
    };
    return CiComponentInjectorService;
}());
exports.CiComponentInjectorService = CiComponentInjectorService;
