"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports._MicroComponents = void 0;
var birthday_component_1 = require("./birthday-component");
var country_component_1 = require("./country-component");
var phone_component_1 = require("./phone-component");
var voucher_code_component_1 = require("./voucher-code-component");
exports._MicroComponents = [
    birthday_component_1.BirthdayComponent,
    voucher_code_component_1.VoucherCodeComponent,
    country_component_1.CountryComponent,
    phone_component_1.PhoneComponent,
];
