"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeadService = void 0;
var api_wrapper_1 = require("../api/api-wrapper");
var manychat_api_1 = require("../api/manychat.api");
var helper_const_1 = require("../const/helper.const");
var infrastructure_const_1 = require("../const/infrastructure.const");
var input_output_helper_const_1 = require("../const/input-output-helper.const");
var input_mapping_const_1 = require("../const/input.mapping.const");
var leads_const_1 = require("../const/leads.const");
var object_service_1 = require("../object-service");
var mappings_const_1 = require("../modules/lead-form/mappings.const");
var settings_service_1 = require("./settings.service");
var LeadService = /** @class */ (function () {
    function LeadService() {
        this.apiWrapper = object_service_1.ObjectService.getInstance(api_wrapper_1.ApiWrapper);
        this.manyChatApi = object_service_1.ObjectService.getInstance(manychat_api_1.ManyChatApi);
        this.config = object_service_1.ObjectService.getInstance(settings_service_1.SettingsService);
    }
    LeadService.prototype.getLeadFromLocalStorage = function () {
        var _a;
        if (localStorage.getItem('leadhash')) {
            var lead_1 = {};
            for (var key in mappings_const_1._LeadFormMappings) {
                var value = localStorage.getItem(mappings_const_1._LeadFormMappings[key].localStorageKey);
                if (value) {
                    lead_1[key] = value;
                }
            }
            leads_const_1._UtmFields.forEach(function (utm) {
                if (localStorage.getItem(utm)) {
                    lead_1[utm] = localStorage.getItem(utm);
                }
                if (localStorage.getItem(utm + '_last')) {
                    lead_1[utm + '_last'] = localStorage.getItem(utm + '_last');
                }
            });
            if (localStorage.getItem(leads_const_1._FirstVisit)) {
                lead_1.firstVisit = new Date(localStorage.getItem(leads_const_1._FirstVisit));
            }
            if (localStorage.getItem('leadhash')) {
                lead_1.leadhash = localStorage.getItem('leadhash');
            }
            if ((0, input_output_helper_const_1._ReadFieldValue)(input_mapping_const_1._AllMappings.studioId)) {
                lead_1.studioId = +(0, input_output_helper_const_1._ReadFieldValue)(input_mapping_const_1._AllMappings.studioId);
            }
            lead_1.paymentTechnology =
                (_a = localStorage.getItem('paymentTechnology')) !== null && _a !== void 0 ? _a : undefined;
            if ((0, input_output_helper_const_1._ReadFieldValue)(input_mapping_const_1._AllMappings.studioId)) {
                lead_1.studioId = +(0, input_output_helper_const_1._ReadFieldValue)(input_mapping_const_1._AllMappings.studioId);
            }
            if (!(lead_1 === null || lead_1 === void 0 ? void 0 : lead_1.tenant)) {
                lead_1.tenant = this.config.get.tenantKey;
            }
            return lead_1;
        }
        return undefined;
    };
    /**
     * Create an entry in our firestore lead collection
     * @param leadhash
     * @param lead
     */
    LeadService.prototype.createFirestoreLead = function (leadhash, lead) {
        return __awaiter(this, void 0, void 0, function () {
            var firstVisit, utmFields_1, aov, membership, membershipName, e_1;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        firstVisit = localStorage.getItem(leads_const_1._FirstVisit);
                        utmFields_1 = {};
                        leads_const_1._UtmFields.forEach(function (a) {
                            if (localStorage.getItem(a)) {
                                utmFields_1[a] = localStorage.getItem(a);
                            }
                        });
                        aov = localStorage.getItem('aov')
                            ? JSON.parse(localStorage.getItem('aov') || '')
                            : undefined;
                        membership = localStorage.getItem('Memberships') || undefined;
                        membershipName = localStorage.getItem('membershipName') || undefined;
                        return [4 /*yield*/, this.sendFirestoreLead(__assign(__assign(__assign({}, lead), utmFields_1), { firstVisit: firstVisit ? new Date(firstVisit) : undefined, membership: membership, membershipName: membershipName, aov: aov, clv: (aov === null || aov === void 0 ? void 0 : aov.total)
                                    ? (0, helper_const_1._RoundUpToTwoDecimals)(aov.total * this.config.get.averageCustomerDuration)
                                    : undefined, leadhash: leadhash, version: '1.0', termDuration: localStorage.getItem('termDuration') || undefined, studioName: localStorage.getItem('StudioName') ||
                                    ((_a = this.config.get) === null || _a === void 0 ? void 0 : _a.studioName) ||
                                    undefined, tenant: this.config.get.tenantKey, leadSource: $('[name="Leadsource"]:checked').val() }))];
                    case 1:
                        _b.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _b.sent();
                        console.error('LEAD CREATION ERROR', e_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    LeadService.prototype.createTechnologyTypeLead = function (lead) {
        return __awaiter(this, void 0, void 0, function () {
            var response, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.apiWrapper.createLead(lead)];
                    case 1:
                        response = _a.sent();
                        if (response === null || response === void 0 ? void 0 : response.uuid) {
                            localStorage.setItem('leaduuid', String(response.uuid));
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _a.sent();
                        console.error('SEVER ERROR HAPPENED', e_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    /**
     *  Create a lead in manychat
     * @param lead
     */
    LeadService.prototype.createManychatLead = function (lead) {
        return __awaiter(this, void 0, void 0, function () {
            var manyChatObject, leadResponse, e_3;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        manyChatObject = {
                            consent_phrase: 'Consent',
                            has_opt_in_email: true,
                            has_opt_in_sms: true,
                            gender: 'unknown',
                            first_name: lead.firstname,
                            last_name: lead.lastname,
                            whatsapp_phone: lead.phone,
                            name: lead.firstname + ' ' + lead.lastname,
                            tenantId: this.config.get.tenantKey,
                            studioId: lead.studioId,
                            manychatTenant: ((_b = (_a = this.config.get) === null || _a === void 0 ? void 0 : _a.leadForm) === null || _b === void 0 ? void 0 : _b.manychatTenant) ||
                                this.config.get.tenantKey,
                        };
                        return [4 /*yield*/, this.manyChatApi.createLeadInManyChat(manyChatObject)];
                    case 1:
                        leadResponse = _c.sent();
                        if ((leadResponse === null || leadResponse === void 0 ? void 0 : leadResponse.success) && (leadResponse === null || leadResponse === void 0 ? void 0 : leadResponse.id)) {
                            localStorage.setItem('manychat-lead-id', leadResponse.id);
                        }
                        else if (leadResponse.error && this.config.get.environment === 'dev') {
                            //TODO: fix
                            // this.errorHandling.showErrorMessage(leadResponse.error);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        e_3 = _c.sent();
                        console.error('Is manychat / strato down??');
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    LeadService.prototype.sendFirestoreLead = function (body) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, fetch(infrastructure_const_1._CloudFunctions.leads + '/create', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(body),
                    })
                        .then(function (createResponse) {
                        if (!createResponse.ok) {
                            return Promise.reject(createResponse.json());
                        }
                        return createResponse.json();
                    })
                        .then(function (created) {
                        return created;
                    })
                        .catch(function (createdErr) { return __awaiter(_this, void 0, void 0, function () {
                        var error;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, createdErr];
                                case 1:
                                    error = _a.sent();
                                    throw error;
                            }
                        });
                    }); })];
            });
        });
    };
    LeadService.prototype.finishLead = function () {
        return __awaiter(this, void 0, void 0, function () {
            var lead;
            var _this = this;
            return __generator(this, function (_a) {
                lead = this.getLeadFromLocalStorage();
                lead.lastVisit = new Date();
                if (lead.firstVisit && lead.lastVisit) {
                    lead.daysFromLeadToMember = (0, helper_const_1._DiffTimes)(lead.firstVisit, lead.lastVisit).days;
                }
                return [2 /*return*/, fetch(infrastructure_const_1._CloudFunctions.leads + '/converted-to-member', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(lead),
                    })
                        .then(function (createResponse) {
                        if (!createResponse.ok) {
                            return Promise.reject(createResponse.json());
                        }
                        return createResponse.json();
                    })
                        .then(function (created) {
                        return created;
                    })
                        .catch(function (createdErr) { return __awaiter(_this, void 0, void 0, function () {
                        var error;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, createdErr];
                                case 1:
                                    error = _a.sent();
                                    throw error;
                            }
                        });
                    }); })];
            });
        });
    };
    return LeadService;
}());
exports.LeadService = LeadService;
