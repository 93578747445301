"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.YondApi = void 0;
var error_handling_1 = require("../error-handling");
var object_service_1 = require("../object-service");
var settings_service_1 = require("../services/settings.service");
var YondApi = /** @class */ (function () {
    function YondApi() {
        this.config = object_service_1.ObjectService.getInstance(settings_service_1.SettingsService);
        this.errorHandling = object_service_1.ObjectService.getInstance(error_handling_1.ErrorHandling);
    }
    YondApi.prototype.getStudios = function () {
        return __awaiter(this, void 0, void 0, function () {
            var studioUrl;
            var _this = this;
            return __generator(this, function (_a) {
                studioUrl = "".concat(this.config.get.yondUrl, "/studios?tenantKey=").concat(encodeURIComponent(this.config.get.tenantKey));
                return [2 /*return*/, fetch(studioUrl, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                        .then(function (createResponse) { return __awaiter(_this, void 0, void 0, function () {
                        var yondStudios;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!createResponse.ok) {
                                        return [2 /*return*/, Promise.reject(createResponse.json())];
                                    }
                                    return [4 /*yield*/, createResponse.json()];
                                case 1:
                                    yondStudios = (_a.sent());
                                    console.log("got studios", yondStudios);
                                    return [2 /*return*/, this.convertYondStudios(yondStudios)];
                            }
                        });
                    }); })
                        .catch(function (createdErr) { return __awaiter(_this, void 0, void 0, function () {
                        var error;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, createdErr];
                                case 1:
                                    error = _a.sent();
                                    if (error.message) {
                                        this.errorHandling.showErrorMessage(error.message);
                                    }
                                    throw error;
                            }
                        });
                    }); })];
            });
        });
    };
    YondApi.prototype.createNewCustomer = function (body) {
        return __awaiter(this, void 0, void 0, function () {
            var yondCustomer, createCustomerUrl;
            return __generator(this, function (_a) {
                yondCustomer = this.convertToYondCustomer(body);
                createCustomerUrl = "".concat(this.config.get.yondUrl, "/customer?tenantKey=").concat(encodeURIComponent(this.config.get.tenantKey));
                return [2 /*return*/, fetch(createCustomerUrl, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(yondCustomer),
                    })];
            });
        });
    };
    YondApi.prototype.createLead = function (body) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, Promise.resolve()];
            });
        });
    };
    YondApi.prototype.getOfferings = function (studioId) {
        return __awaiter(this, void 0, void 0, function () {
            var offeringUrl;
            var _this = this;
            return __generator(this, function (_a) {
                offeringUrl = "".concat(this.config.get.yondUrl, "/offerings?tenantKey=").concat(encodeURIComponent(this.config.get.tenantKey));
                return [2 /*return*/, fetch(offeringUrl, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                        .then(function (createResponse) { return __awaiter(_this, void 0, void 0, function () {
                        var yondMemberships;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!createResponse.ok) {
                                        return [2 /*return*/, Promise.reject(createResponse.json())];
                                    }
                                    return [4 /*yield*/, createResponse.json()];
                                case 1:
                                    yondMemberships = (_a.sent());
                                    console.log("got memberships", yondMemberships);
                                    return [2 /*return*/, this.convertYondOffers(yondMemberships)];
                            }
                        });
                    }); })
                        .catch(function (createdErr) { return __awaiter(_this, void 0, void 0, function () {
                        var error;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, createdErr];
                                case 1:
                                    error = _a.sent();
                                    if (error.message) {
                                        this.errorHandling.showErrorMessage(error.message);
                                    }
                                    throw error;
                            }
                        });
                    }); })];
            });
        });
    };
    YondApi.prototype.convertYondOffers = function (offerings) {
        var _this = this;
        return offerings.map(function (offering) {
            return {
                id: +offering.oid,
                description: offering.description,
                name: offering.name,
                subDescription: offering.category.description,
                preuseType: null,
                terms: _this.convertYondOfferTerm(offering.contract_terms, offering),
                modules: _this.convertYondModules(offering.benefits), // all addons 
                maximumNumberOfSelectableModules: undefined,
                selectableModules: [], // not per default in package
                imageUrl: null,
                footnote: null, // highlight contract details
            };
        });
    };
    // TODO: technically we have to take in count the period type (month, year) for the termValueWithoutBonusPeriod
    YondApi.prototype.convertYondOfferTerm = function (terms, offering) {
        return terms.map(function (term) {
            return {
                id: +term.oid,
                defaultContractStartDate: null, // date when the contract starts
                contractStartDateOfUse: null,
                termValue: term.amount, // important number of months/days per term
                termValueWithoutBonusPeriod: term.amount - offering.bonus_period,
                termUnit: term.period,
                paymentFrequencyValue: 1,
                paymentFrequencyUnit: offering.currency,
                paymentFrequencyType: "YEAR",
                price: offering.price, // think its per month/term not total
                priceAdjustmentRules: [],
                flatFees: [], // TODO
                extensionFixedTerm: null,
                extensionFixedTermUnit: null,
                extensionType: null,
                cancellationPeriod: null, // TODO
                cancellationPeriodUnit: null,
                extensionCancellationPeriod: null,
                extensionCancellationPeriodUnit: null,
                rateBonusPeriods: [],
                rateStartPrice: null,
                optionalModules: [], // add ons optional in contract
            };
        });
    };
    YondApi.prototype.convertYondModules = function (yondBenefits) {
        return yondBenefits.map(function (benefit) {
            var bef = benefit.benefit;
            return {
                id: +bef.oid,
                name: bef.name,
                description: bef.description || "",
                paymentFrequencyValue: 0,
                paymentFrequencyUnit: "",
                paymentFrequencyType: "",
                price: 0,
                imageUrl: "",
            };
        });
    };
    YondApi.prototype.convertYondStudios = function (yondStudios) {
        return yondStudios.map(function (yondStudio) {
            var _a, _b, _c, _d, _e, _f, _g;
            return {
                id: yondStudio.id,
                studioName: yondStudio.name,
                studioPhone: (_a = yondStudio.support_phone) === null || _a === void 0 ? void 0 : _a.number,
                studioEmail: (_b = yondStudio.support_email) === null || _b === void 0 ? void 0 : _b.address,
                trialSessionBookable: false,
                address: {
                    street: ((_c = yondStudio.address) === null || _c === void 0 ? void 0 : _c.street) || "",
                    city: ((_d = yondStudio.address) === null || _d === void 0 ? void 0 : _d.city) || "",
                    zipCode: ((_e = yondStudio.address) === null || _e === void 0 ? void 0 : _e.postal) || "",
                    countryCode: ((_f = yondStudio.address) === null || _f === void 0 ? void 0 : _f.country.dial_code) || "",
                    countryCodeAlpha2: ((_g = yondStudio.address) === null || _g === void 0 ? void 0 : _g.country.dial_code) || "", // Assuming the same as `countryCode`
                },
                zoneId: 'Unknown Zone', // Placeholder if zone data is not available
                masterStudioId: null,
                openingDate: null,
                closingDate: null,
                openingHours: [],
                currencyCode: "EUR",
            };
        });
    };
    YondApi.prototype.convertToYondCustomer = function (body) {
        var customer = {
            person: {
                gender: body.customer.gender == "UNISEX" ? "NONBINARY" : body.customer.gender,
                birth_dt: body.customer.dateOfBirth,
                name: {
                    first_name: body.customer.firstname,
                    last_name: body.customer.lastname,
                },
                addresses: [
                    {
                        country_oid: "65", // TODO: dont use Germany hardcoded
                        city: body.customer.city,
                        postal: body.customer.zipCode,
                        street: body.customer.street,
                        house_nr: body.customer.houseNumber,
                    }
                ],
                phones: [
                    {
                        number: "+49" + body.customer.telephone_mobile, // TODO interface does not include selected phone prefix
                        country_oid: "65",
                    }
                ],
                emails: [
                    {
                        address: body.customer.email,
                    }
                ]
            }
        };
        return customer;
    };
    //   // This can be used e.g. to validate a voucher
    YondApi.prototype.previewNewCustomer = function (body) {
        console.error('PREVIEW CUSTOMER NOT DEFINED FOR YOND API');
        return undefined;
    };
    YondApi.prototype.validateVoucher = function (body) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                console.error('VOUCHERS NOT DEFINED FOR YOND API');
                return [2 /*return*/, undefined];
            });
        });
    };
    return YondApi;
}());
exports.YondApi = YondApi;
