"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FitposApi = void 0;
var object_service_1 = require("../object-service");
var api_variables_const_1 = require("../modules/fitpos/const/api-variables.const");
var endpoints_const_1 = require("../modules/fitpos/const/endpoints.const");
var storage_service_1 = require("../modules/fitpos/services/storage.service");
var FitposApi = /** @class */ (function () {
    function FitposApi() {
        this.storageService = object_service_1.ObjectService.getInstance(storage_service_1.StorageService);
    }
    FitposApi.prototype.getCustomerById = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.getData(endpoints_const_1.GetEndpoint.Customer, id, {})];
            });
        });
    };
    FitposApi.prototype.getCancellationReasons = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.getData(endpoints_const_1.GetEndpoint.CancellationReason, id, {})];
            });
        });
    };
    FitposApi.prototype.postCustomerDataAmendment = function (id, amendments) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                //TODO return type
                return [2 /*return*/, this.postAmendment(endpoints_const_1.PostEndpoint.CustomerAmendment, id, amendments)];
            });
        });
    };
    FitposApi.prototype.postContractCancelation = function (id, cancelation) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.postAmendment(endpoints_const_1.PostEndpoint.ContractCancelation, id, cancelation)];
            });
        });
    };
    FitposApi.prototype.getCustomerContractData = function (id, status) {
        return __awaiter(this, void 0, void 0, function () {
            var params;
            return __generator(this, function (_a) {
                params = {};
                if (status) {
                    params['status'] = status;
                }
                return [2 /*return*/, this.getData(endpoints_const_1.GetEndpoint.Contracts, id, params)];
            });
        });
    };
    FitposApi.prototype.getCustomerModuleData = function (customerId, contractId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.getData(endpoints_const_1.GetEndpoint.PurchasableModules, customerId, { contractId: contractId })];
            });
        });
    };
    FitposApi.prototype.postCustomerModuleData = function (contractId, module) {
        return __awaiter(this, void 0, void 0, function () {
            var authHeader, headers;
            var _this = this;
            return __generator(this, function (_a) {
                authHeader = this.storageService.getAuthHeaders();
                headers = __assign({ 'Content-Type': 'application/json' }, authHeader);
                return [2 /*return*/, fetch(api_variables_const_1.CLOUD_FUNCTION_URL + endpoints_const_1.PostEndpoint.PurchaseModule, {
                        method: 'POST',
                        headers: headers,
                        body: JSON.stringify(module),
                    })
                        .then(function (createResponse) {
                        if (!createResponse.ok) {
                            return Promise.reject(createResponse.json());
                        }
                        return createResponse.json();
                    })
                        .catch(function (createdErr) { return __awaiter(_this, void 0, void 0, function () {
                        var error;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, createdErr];
                                case 1:
                                    error = _a.sent();
                                    throw error;
                            }
                        });
                    }); })];
            });
        });
    };
    FitposApi.prototype.postAmendment = function (endpoint, id, body) {
        return __awaiter(this, void 0, void 0, function () {
            var params, authHeader, headers;
            var _this = this;
            return __generator(this, function (_a) {
                params = this.getParams(id);
                authHeader = this.storageService.getAuthHeaders();
                headers = __assign({ 'Content-Type': 'application/json' }, authHeader);
                //CRIT: remove for production
                if (localStorage.getItem('skipCheck')) {
                    params['skipCheck'] = true;
                }
                return [2 /*return*/, fetch(api_variables_const_1.CLOUD_FUNCTION_URL + endpoint + this.paramsToString(params), {
                        method: 'POST',
                        headers: headers,
                        body: JSON.stringify(body),
                    })
                        .then(function (createResponse) {
                        if (!createResponse.ok) {
                            return Promise.reject(createResponse.json());
                        }
                        return createResponse.json();
                    })
                        .catch(function (createdErr) { return __awaiter(_this, void 0, void 0, function () {
                        var error;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, createdErr];
                                case 1:
                                    error = _a.sent();
                                    throw error;
                            }
                        });
                    }); })];
            });
        });
    };
    FitposApi.prototype.postAdditionalModuleContract = function (customerId, contractId, module) {
        return __awaiter(this, void 0, void 0, function () {
            var params, authHeader, headers;
            var _this = this;
            return __generator(this, function (_a) {
                params = this.getParams(customerId);
                authHeader = this.storageService.getAuthHeaders();
                headers = __assign({ 'Content-Type': 'application/json' }, authHeader);
                //CRIT: remove for production
                if (localStorage.getItem('skipCheck')) {
                    params['skipCheck'] = true;
                }
                console.log('Send request', module, api_variables_const_1.CLOUD_FUNCTION_URL + endpoints_const_1.PostEndpoint.PurchaseModule + this.paramsToString(__assign(__assign({}, params), { contractId: contractId })));
                return [2 /*return*/, fetch(api_variables_const_1.CLOUD_FUNCTION_URL + endpoints_const_1.PostEndpoint.PurchaseModule + this.paramsToString(__assign(__assign({}, params), { contractId: contractId })), {
                        method: 'POST',
                        headers: headers,
                        body: JSON.stringify(module),
                    })
                        .then(function (createResponse) {
                        if (!createResponse.ok) {
                            return Promise.reject(createResponse.json());
                        }
                        return createResponse.json();
                    })
                        .catch(function (createdErr) { return __awaiter(_this, void 0, void 0, function () {
                        var error;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, createdErr];
                                case 1:
                                    error = _a.sent();
                                    throw error;
                            }
                        });
                    }); })];
            });
        });
    };
    FitposApi.prototype.getData = function (endpoint, customerId, additionalParams) {
        return __awaiter(this, void 0, void 0, function () {
            var params, authHeader, headers;
            var _this = this;
            return __generator(this, function (_a) {
                params = this.getParams(customerId);
                params = __assign(__assign({}, params), additionalParams);
                authHeader = this.storageService.getAuthHeaders();
                headers = __assign({ 'Content-Type': 'application/json' }, authHeader);
                //CRIT: remove for production
                if (localStorage.getItem('skipCheck')) {
                    params['skipCheck'] = true;
                }
                return [2 /*return*/, fetch(api_variables_const_1.CLOUD_FUNCTION_URL + endpoint + this.paramsToString(params), {
                        method: 'GET',
                        headers: headers,
                    })
                        .then(function (createResponse) {
                        if (!createResponse.ok) {
                            return Promise.reject(createResponse.json());
                        }
                        return createResponse.json();
                    })
                        .catch(function (createdErr) { return __awaiter(_this, void 0, void 0, function () {
                        var error;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, createdErr];
                                case 1:
                                    error = _a.sent();
                                    throw error;
                            }
                        });
                    }); })];
            });
        });
    };
    FitposApi.prototype.getParams = function (customerId) {
        var params = {};
        params[api_variables_const_1.CUSTOMER_ID] = customerId;
        params[api_variables_const_1.FACILITY_ID] = this.storageService.getFacilityId();
        return params;
    };
    FitposApi.prototype.paramsToString = function (params) {
        var result = '';
        Object.keys(params).forEach(function (key) {
            if (result == '') {
                result += '?';
            }
            else {
                result += '&';
            }
            result += key + '=' + params[key];
        });
        return result;
    };
    return FitposApi;
}());
exports.FitposApi = FitposApi;
