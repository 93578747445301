"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MAPPINGS = exports.CheckboxMappingTable = exports.ButtonMappingTable = exports.InputFieldMappingTable = exports.TextFieldMappingTable = void 0;
exports.TextFieldMappingTable = {
    birthdayInfoText: {
        id: '#birthdayInfo',
    },
    genderInput: {
        id: '#genderInput',
    },
    dateOfBirth: {
        id: '#dateOfBirth',
        required: false,
        localStorageKey: 'birthday',
    },
    studioNameText: { id: '#studioName', localStorageKey: 'studioName' },
    membershipNameText: {
        id: '#membershipName',
        localStorageKey: 'membershipName',
    },
    membershipPriceText: {
        id: '#membershipPrice',
        localStorageKey: 'membershipPrice',
    },
};
exports.InputFieldMappingTable = {
    guardian: {
        id: '#guardian',
        required: true,
    },
    firstname: {
        id: '#firstName',
        required: true,
        localStorageKey: 'Vorname',
        disabled: true,
    },
    lastname: {
        id: '#lastName',
        localStorageKey: 'Nachname',
        required: true,
        disabled: true,
    },
    gender: {
        id: 'input[name="gender"]:checked',
        localStorageKey: 'Geschlecht',
        required: true,
    },
    iban: { id: '#accountIban', required: true },
    bic: { id: '#BIC', required: true },
    zipCode: {
        id: '#zipCode',
        required: true,
    },
    referralCode: { id: '#referralCode' },
    voucherCode: { id: '#voucherCode' },
    telephone_mobile: {
        id: '#telephone_mobile',
        localStorageKey: 'Telefon',
        disabled: true,
        required: true,
    },
    accountHolder: {
        id: '#accountHolder',
        required: true,
    },
    membershipId: {
        id: '#membershipId',
        localStorageKey: 'Memberships',
        disabled: true,
    },
    houseNumber: {
        id: '#houseNumber',
        required: true,
    },
    city: {
        id: '#localCity',
        required: true,
    },
    email: {
        id: '#eMail',
        required: true,
        localStorageKey: 'E-Mail',
        disabled: true,
    },
    location: { id: '#locationId', localStorageKey: 'Location', disabled: true },
    gdprInput: { id: '#gdprField', localStorageKey: 'GDPR' },
    membershipNameInput: {
        id: '#membershipNameInput',
        localStorageKey: 'membershipName',
        disabled: true,
    },
    street: {
        id: '#streetAdress',
        required: true,
    },
};
exports.ButtonMappingTable = {
    submitButton: { id: '#Membership-Submit' },
    webflowSubmitButton: { id: '#webflowSubmitButton' },
    paymentChoiceButton: { id: '#payment-choices' },
};
exports.CheckboxMappingTable = {
    consent: {
        id: '#checkboxConsent',
        required: true,
    },
};
exports.MAPPINGS = __assign(__assign(__assign(__assign({ 
    //unspecified mappings
    yearOfBirth: { id: '#yearOfBirth', localStorageKey: 'yearOfBirth' }, monthOfBirth: { id: '#monthOfBirth', localStorageKey: 'monthOfBirth' }, dayOfBirth: { id: '#dayOfBirth', localStorageKey: 'dayOfBirth' }, guardianWrapper: { id: '#guardian-wrapper' } }, exports.TextFieldMappingTable), exports.ButtonMappingTable), exports.CheckboxMappingTable), exports.InputFieldMappingTable);
