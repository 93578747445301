"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeadForm = void 0;
var input_output_helper_const_1 = require("../../const/input-output-helper.const");
var input_mapping_const_1 = require("../../const/input.mapping.const");
var element_generator_1 = require("../../element-generator");
var helper_1 = require("../../helper");
var object_service_1 = require("../../object-service");
var lead_service_1 = require("../../services/lead.service");
var settings_service_1 = require("../../services/settings.service");
var send_email_service_1 = require("../finish/services/send-email.service");
var mappings_const_1 = require("./mappings.const");
var LeadForm = /** @class */ (function () {
    function LeadForm() {
        this.config = object_service_1.ObjectService.getInstance(settings_service_1.SettingsService);
        this.elementGenerator = object_service_1.ObjectService.getInstance(element_generator_1.ElementGenerator);
        this.helper = object_service_1.ObjectService.getInstance(helper_1.Helper);
        this.sendEmailService = object_service_1.ObjectService.getInstance(send_email_service_1.SendEmailService);
        this.leadService = object_service_1.ObjectService.getInstance(lead_service_1.LeadService);
    }
    LeadForm.prototype.loadModule = function () {
        var _this = this;
        this.prepareEmployeeView();
        this.preparePhoneField();
        this.elementGenerator.createElements();
        var submitButton = $(mappings_const_1._LeadFormButtons.submitButton.id);
        //Submit the form
        if (submitButton === null || submitButton === void 0 ? void 0 : submitButton.length) {
            submitButton.on('click', function () { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.sendForm()];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            }); });
        }
    };
    LeadForm.prototype.prepareEmployeeView = function () {
        var _a;
        if ((_a = this.config.get) === null || _a === void 0 ? void 0 : _a.employeeVersion) {
            $('[data-employee="true"]').css('display', 'flex');
        }
    };
    LeadForm.prototype.gatherData = function () {
        var lead = {};
        var valid = true;
        for (var key in mappings_const_1._LeadFormMappings) {
            var result = this.helper.get$Val(mappings_const_1._LeadFormMappings[key]);
            if (result && mappings_const_1._LeadFormMappings[key].localStorageKey) {
                //remove leading 0 on phone numbers
                localStorage.setItem(mappings_const_1._LeadFormMappings[key].localStorageKey, result.trim());
                if (key !== 'checkbox') {
                    lead[key] = result.trim();
                }
            }
            else if (mappings_const_1._LeadFormMappings[key].required) {
                valid = false;
                console.error('Invalid form');
            }
        }
        return valid ? lead : undefined;
    };
    LeadForm.prototype.sendForm = function () {
        return __awaiter(this, void 0, void 0, function () {
            var lead, leadhash, oldLeadHash, e_1;
            var _a, _b, _c, _d, _e, _f, _g;
            return __generator(this, function (_h) {
                switch (_h.label) {
                    case 0:
                        //if settings object injects the phoneprefix or by default +49
                        this.preparePhoneField();
                        lead = this.gatherData();
                        if (lead === undefined) {
                            return [2 /*return*/];
                        }
                        //hash is to determine if user has changed sth (new lead)
                        lead.studioId = +(0, input_output_helper_const_1._ReadFieldValue)(input_mapping_const_1._AllMappings.studioId);
                        lead.phone =
                            localStorage.getItem('phonePrefix') +
                                localStorage.getItem(mappings_const_1._LeadFormMappings.phone.localStorageKey);
                        leadhash = this.helper.generateHash(JSON.stringify(lead));
                        oldLeadHash = localStorage.getItem('leadhash');
                        if (!(leadhash !== oldLeadHash)) return [3 /*break*/, 10];
                        this.helper.createLoadingScreen();
                        if (!!((_b = (_a = this.config.get) === null || _a === void 0 ? void 0 : _a.leadForm) === null || _b === void 0 ? void 0 : _b.skipLeadCreation)) return [3 /*break*/, 3];
                        localStorage.setItem('leadhash', String(leadhash));
                        return [4 /*yield*/, this.leadService.createTechnologyTypeLead(lead)];
                    case 1:
                        _h.sent();
                        return [4 /*yield*/, this.leadService.createFirestoreLead(leadhash, lead)];
                    case 2:
                        _h.sent();
                        _h.label = 3;
                    case 3:
                        if (!((_d = (_c = this.config.get) === null || _c === void 0 ? void 0 : _c.receiverMail) === null || _d === void 0 ? void 0 : _d.sendLeadEmail)) return [3 /*break*/, 7];
                        _h.label = 4;
                    case 4:
                        _h.trys.push([4, 6, , 7]);
                        return [4 /*yield*/, this.sendEmailService.sendEmail(this.config.get.receiverMail.sendLeadEmail, lead, 'Funnel lead')];
                    case 5:
                        _h.sent();
                        return [3 /*break*/, 7];
                    case 6:
                        e_1 = _h.sent();
                        return [3 /*break*/, 7];
                    case 7:
                        if (!((_f = (_e = this.config.get) === null || _e === void 0 ? void 0 : _e.leadForm) === null || _f === void 0 ? void 0 : _f.manyChat)) return [3 /*break*/, 9];
                        return [4 /*yield*/, this.leadService.createManychatLead(lead)];
                    case 8:
                        _h.sent();
                        _h.label = 9;
                    case 9:
                        this.helper.deleteLoadingScreen();
                        _h.label = 10;
                    case 10:
                        //overwrite phone input field - shortly before redirecting to webflow forms
                        if ($(mappings_const_1._LeadFormMappings.phone.id).length) {
                            $(mappings_const_1._LeadFormMappings.phone.id).val(lead.phone);
                        }
                        //Next button we done here
                        $(mappings_const_1._LeadFormButtons.webflowSubmitButton.id).click();
                        if ((_g = this.config.get) === null || _g === void 0 ? void 0 : _g.redirect) {
                            window.location.href = this.config.get.redirect;
                        }
                        return [2 /*return*/, true];
                }
            });
        });
    };
    LeadForm.prototype.preparePhoneField = function () {
        var _a, _b;
        var phonePrefix = ((_b = (_a = this.config.get) === null || _a === void 0 ? void 0 : _a.leadForm) === null || _b === void 0 ? void 0 : _b.phonePrefix) || '+49';
        if (!localStorage.getItem('phonePrefix')) {
            (0, input_output_helper_const_1._SaveFieldInLocalstorage)(input_mapping_const_1._CustomerMapping.phonePrefix, phonePrefix);
        }
        if ($(mappings_const_1._LeadFormMappings.phone.id).length) {
            var result = String($(mappings_const_1._LeadFormMappings.phone.id).val());
            result = result.replace(/^0+/, '');
            if (phonePrefix) {
                var splitted = result.split(phonePrefix);
                result = splitted.length > 1 ? splitted[1] : splitted[0];
            }
            $(mappings_const_1._LeadFormMappings.phone.id).val(result);
        }
    };
    return LeadForm;
}());
exports.LeadForm = LeadForm;
